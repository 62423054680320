/*----------------------------------------*/
/*  14. Brand
/*----------------------------------------*/
.brand {
    &-bg {
        border-radius: 10px;
        padding: 90px;
        @include breakpoint(max-sm-device) {
            padding: 30px;
        }
    }
    &-slider {
        .swiper {
            &-wrapper {
                align-items: center;
            }
        }
    }
    &-item {
        display: flex;
        justify-content: center;
        opacity: 0.5;
        transition: $baseTransition;
        &:hover {
            opacity: 1;
        }
    }
    &-nav {
        background-color: #f4f4f4;
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 80px;
        padding-right: 80px;
    }
}
