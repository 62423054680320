//--- All Variables - Related Stuff Included In This Area ---↓

// Font Family
$rubik-font: 'Rubik', sans-serif;

// Transition
$baseTransition: all 0.3s ease 0s;

// Color
$pronia-primary: #abd373;
$pronia-secondary: #525252;
$white: #ffffff;

// Border Color
$border-color: #dee2e6;
