/*----------------------------------------*/
/*  17. Sidebar
/*----------------------------------------*/
.widgets {
    &-searchbox {
        background-color: #f6f7fb;
        border-radius: 10px;
        position: relative;
        margin-bottom: 40px;
        padding: 30px;
        .input-field {
            border: 1px solid $border-color;
            background-color: #f6f7fb;
            border-radius: 10px;
            width: 100%;
            height: 45px;
            line-height: 45px;
            padding-left: 15px;
            padding-right: 55px;
            color: #969696;
            &::placeholder {
                opacity: 1;
            }
        }
        &-btn {
            background-color: transparent;
            border: 0;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            padding-left: 20px;
            padding-right: 20px;
            color: #cfcfcf;
            i {
                vertical-align: middle;
            }
        }
    }
    &-title {
        padding-bottom: 15px;
        text-align: center;
        position: relative;
        color: #6c6c6c;
        font-size: 24px;
        &:before {
            background-color: $pronia-primary;
            content: '';
            width: 30px;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &-area {
        background-color: #f6f7fb;
        border-radius: 10px;
        padding-top: 55px;
        padding-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }
    &-item {
        padding-top: 50px;
    }
    &-category {
        li {
            &:not(:last-child) {
                a {
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
            }
            a {
                color: #6a6a6a;
                display: block;
                &:hover {
                    color: $pronia-primary;
                }
            }
            i {
                vertical-align: middle;
                font-size: 10px;
            }
        }
    }
    &-list {
        &-slider {
            [class*='swiper-slide'] {
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                }
            }
        }
        &-item {
            display: flex;
        }
        &-img {
            width: 70px;
        }
        &-content {
            width: calc(100% - 60px);
            padding-left: 10px;
            .widgets {
                &-meta {
                    ul {
                        li {
                            &.date {
                                text-transform: uppercase;
                                color: $pronia-primary;
                                padding-bottom: 5px;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .title {
                color: #383838;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
    &-tag {
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 10px;
            margin-bottom: 15px;
            a {
                border: 1px solid $border-color;
                border-radius: 10px;
                display: block;
                padding-top: 5px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 5px;
                color: #7e7e7e;
                &:hover {
                    background-color: $pronia-primary;
                    border-color: $pronia-primary;
                    color: $white;
                }
            }
        }
    }
    &-banner {
        margin-top: 40px;
    }
}
