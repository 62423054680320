/*----------------------------------------*/
/*  08. Newsletter
/*----------------------------------------*/
.newsletter {
    &-area {
        height: 400px;
    }
    &-title {
        margin-bottom: 45px;
        color: #464646;
        font-weight: 500;
        font-size: 48px;
        max-width: 650px;
        @include breakpoint(max-md-device) {
            font-size: 28px;
            line-height: 1.3;
        }
    }
    &-form {
        display: inline-block;
        position: relative;
        .input-field {
            background-color: $pronia-primary;
            border: 1px solid $pronia-primary;
            border-radius: 5px;
            position: relative;
            color: #f1ffde;
            font-size: 16px;
            padding-left: 15px;
            padding-right: 90px;
            width: 425px;
            height: 55px;
            line-height: 55px;
            @include breakpoint(max-xs-device) {
                width: 100%;
            }
        }
        .btn {
            &-wrap {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }
            &-submit {
                position: relative;
                padding: 0;
                &:before {
                    background-color: $white;
                    height: 30px;
                    width: 1px;
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                }
                i {
                    padding: 15px 20px;
                    font-size: 30px;
                    display: block;
                    color: $white;
                }
            }
        }
    }
}

/* ---Mailchimp--- */
.mailchimp {
    &-success {
        color: green;
    }
    &-error {
        color: red;
    }
}
